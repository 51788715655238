import React from 'react'
import MobileMenu from './MobileMenu'
import { Image } from './Texts'
import logo_white from '../assets/images/log-white.svg'
import logo_blue from '../assets/images/log-blue.svg'

import LangSwitcher from './LangSwitcher'
import { TransitionPortal } from 'gatsby-plugin-transition-link'
import { Mobile } from './MediaQueries'
import AnimationLink from './Link'

const timeoutLength = 300

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isBlue: props.isBlue,
      active: false,
      mobileActiveClass: '',
      mouseOverButton: false,
      mouseOverMenu: false,
      mouseOverSubButton: false,
      mouseOverSubMenu: false,
    }
  }

  componentDidMount() {
    this.setState({
      isAuditPage:
        window.location.pathname === '/auditoria-gratis-para-o-seu-site-wordpress' || window.location.pathname === '/auditoria-gratis-para-o-seu-site-wordpress/',
    });
  }

  componentWillMount = () => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('navPanel-visible')
    }
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        // set the class for the mobile menu
        if (this.state.active) {
          this.setState({
            mobileActiveClass: 'navPanel-visible',
          })
          document.body.classList.add('navPanel-visible')
        } else {
          this.setState({
            mobileActiveClass: '',
          })
          document.body.classList.remove('navPanel-visible')
        }
      }
    )
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState)
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    }
  }

  enterButton = () => {
    this.setState({ mouseOverButton: true })
  }

  leaveButton = () => {
    setTimeout(() => {
      this.setState({ mouseOverButton: false })
    }, timeoutLength)
  }

  enterMenu = () => {
    this.setState({ mouseOverMenu: true })
  }

  leaveMenu = () => {
    setTimeout(() => {
      this.setState({ mouseOverMenu: false })
    }, timeoutLength)
  }

  enterSubButton = () => {
    this.setState({ mouseOverSubButton: true })
  }

  leaveSubButton = () => {
    setTimeout(() => {
      this.setState({ mouseOverSubButton: false })
    }, timeoutLength)
  }

  enterSubMenu = () => {
    this.setState({ mouseOverSubMenu: true })
  }

  leaveSubMenu = () => {
    setTimeout(() => {
      this.setState({ mouseOverSubMenu: false })
    }, timeoutLength)
  }

  render() {
    const location = this.props.location
    const isEn = this.props.lang === 'en'
    const pages = this.props.pages
    const open = this.state.mouseOverButton || this.state.mouseOverMenu
    const subOpen = this.state.mouseOverSubButton || this.state.mouseOverSubMenu
    const { mobileActiveClass, isAuditPage } = this.state;
    const mobileMenuClass = `navbar-menu ${mobileActiveClass} ${isAuditPage ? 'audit-menu' : ''}`;

    return (
      <React.Fragment>
        <Mobile>
          <TransitionPortal>
            <div className={mobileMenuClass}>
              <div id="titleBar">
                <div className="mobile-logo">
                  <AnimationLink
                    paintDrip
                    hex={this.props.isBlue ? '#FFF' : '#322EFF'}
                    to={isEn ? '/en/' : '/'}
                  >
                    <Image
                      rootProps={''}
                      title={'log logo'}
                      image={
                        this.state.mobileActiveClass ? logo_white : logo_blue
                      }
                      width={80}
                      height={40}
                    />
                  </AnimationLink>
                </div>
                <a
                  role="button"
                  onClick={() => this.toggleHamburger()}
                  className="toggle"
                  aria-label="Open mobile menu"
                ></a>
              </div>
              <div id="navPanel">
                <MobileMenu pages={pages} />
              </div>
            </div>
          </TransitionPortal>
        </Mobile>

        <header id="header">
          <AnimationLink
            paintDrip
            hex={this.props.isBlue ? '#FFF' : '#322EFF'}
            to={isEn ? '/en/' : '/'}
            className={'logo'}
          >
            <Image
              rootProps={''}
              title={'log logo'}
              image={this.state.isBlue ? logo_white : logo_blue}
              width={100}
              height={50}
            />
          </AnimationLink>

          <nav className={this.state.isBlue ? 'is-blue' : ''}>
            <ul style={{ display: 'flex', flex: 1 }}>
              {pages.map((link, index) => {
                // at least 2lvl menu
                if (link.items) {
                  return (
                    <React.Fragment key={link.name}>
                      <li key={link.name}>
                        <AnimationLink
                          paintDrip
                          hex={this.props.isBlue ? '#FFF' : '#322EFF'}
                          onMouseEnter={this.enterButton}
                          onMouseLeave={this.leaveButton}
                          className={link.cl}
                          to={link.link}
                        >
                          {link.name}
                        </AnimationLink>
                        <ul
                          style={
                            open
                              ? {
                                  display: `block`,
                                  background: `rgba(39, 40, 51, 0.965)`,
                                  position: `absolute`,
                                  right: `55%`,
                                  minWidth: `150px`,
                                  borderRadius: `5px`,
                                }
                              : { display: `none` }
                          }
                          onMouseEnter={this.enterMenu}
                          onMouseLeave={this.leaveMenu}
                        >
                          {link.items.map(sublink =>
                            sublink.items ? (
                              <React.Fragment key={sublink.name}>
                                <li
                                  key={sublink.name}
                                  style={{
                                    textAlign: `left`,
                                    marginLeft: `0`,
                                    paddingLeft: `0`,
                                    fontSize: `14px`,
                                    display: `block`,
                                    lineHeight: `2.5`,
                                  }}
                                >
                                  <AnimationLink
                                    paintDrip
                                    hex={this.props.isBlue ? '#FFF' : '#322EFF'}
                                    onMouseEnter={this.enterSubButton}
                                    onMouseLeave={this.leaveSubButton}
                                    to={sublink.link}
                                  >
                                    {sublink.name}
                                  </AnimationLink>
                                  <ul
                                    style={
                                      subOpen
                                        ? {
                                            display: `block`,
                                            background: `rgba(39, 40, 51, 0.965)`,
                                            borderRadius: `5px`,
                                            position: `absolute`,
                                            right: `100%`,
                                            width: `100%`,
                                            marginTop: `-35px`,
                                          }
                                        : { display: `none` }
                                    }
                                    onMouseEnter={this.enterSubMenu}
                                    onMouseLeave={this.leaveSubMenu}
                                  >
                                    {sublink.items.map(nestedsublink => (
                                      <li
                                        key={nestedsublink.name}
                                        style={{
                                          textAlign: `left`,
                                          marginLeft: `0`,
                                          paddingLeft: `0`,
                                          fontSize: `14px`,
                                          whiteSpace: `nowrap`,
                                          lineHeight: `2.5`,
                                          display: `block`,
                                        }}
                                      >
                                        <AnimationLink to={nestedsublink.link}>
                                          {nestedsublink.name}
                                        </AnimationLink>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              </React.Fragment>
                            ) : (
                              <li
                                key={sublink.name}
                                style={{
                                  textAlign: `left`,
                                  marginLeft: `0`,
                                  paddingLeft: `0`,
                                  fontSize: `14px`,
                                  lineHeight: `2.5`,
                                  display: `block`,
                                }}
                              >
                                <AnimationLink
                                  paintDrip
                                  hex={this.props.isBlue ? '#FFF' : '#322EFF'}
                                  to={sublink.link}
                                >
                                  {sublink.name}
                                </AnimationLink>
                              </li>
                            )
                          )}
                        </ul>
                      </li>
                    </React.Fragment>
                  )
                }

                //lang switcher attached to last item
                if (pages.length === index + 1) {
                  return (
                    <li key={link.name}>
                      <LangSwitcher />
                      <AnimationLink
                        paintDrip
                        hex={this.props.isBlue ? '#FFF' : '#322EFF'}
                        className={link.cl}
                        to={link.link}
                      >
                        {link.name}
                      </AnimationLink>
                    </li>
                  )
                }

                return (
                  <li
                    key={link.name}
                    className={link.link === location ? `active` : ''}
                  >
                    <AnimationLink
                      paintDrip
                      hex={this.props.isBlue ? '#FFF' : '#322EFF'}
                      className={link.cl}
                      to={link.link}
                    >
                      {link.name}
                    </AnimationLink>
                  </li>
                )
              })}
            </ul>
          </nav>

          <AnimationLink
            paintDrip
            className="button audit-btn audit-anchor-link talktous-header"
          >
            {isEn ? 'Let\'s Talk' : 'Contacte-nos agora'}
          </AnimationLink>

        </header>
      </React.Fragment>
    )
  }
}

export default Header
