import Cookies from 'js-cookie';

let ga_id = '';

const getCookieConsent = () => {
	const cookieConsent = Cookies.get('cookieConsent');

	try {
		return JSON.parse(cookieConsent);
	} catch (e) {
		return [];
	}
};

const setCookieConsent = (types) => {
	types.push('necessary');
	Cookies.set('cookieConsent', JSON.stringify(types), { expires: 30 });

	toggleScripts();

	removeCookies(types);
};

const setCookieConsentSel = () => {
	let checkboxes = document.querySelectorAll('.js-cookie-consent-check');
	let types = new Array();

	checkboxes.forEach((checkbox) => {
		if (checkbox.checked && checkbox.value) {
			types.push(checkbox.value);
		}
	});

	setCookieConsent(types);
};

const selAllCookieConsent = () => {
	let checkboxes = document.querySelectorAll('.js-cookie-consent-check');

	checkboxes.forEach((checkbox) => {
		checkbox.checked = true;
	});
};

const hideCookieNotice = (effect) => {
	let cookieNotice = document.getElementsByClassName('js-cookie-notice')[0];

	if (!effect) {
		return;
	}

	cookieNotice.classList.add('off');

	if (effect === 'instant') {
		cookieNotice.classList.add('instant');
	}
};

const showCookieNotice = () => {
	let cookieNotice = document.getElementsByClassName('js-cookie-notice')[0];
	cookieNotice.classList.remove('instant');
	cookieNotice.classList.remove('off');
};

const showCookieModal = () => {
	let cookieModal = document.getElementsByClassName('js-cookie-modal')[0];
	cookieModal.classList.remove('off');
	document.body.classList.add('no-scroll');
};

const hideCookieModal = () => {
	let cookieModal = document.getElementsByClassName('js-cookie-modal')[0];
	cookieModal.classList.add('off');
	document.body.classList.remove('no-scroll');
};

const toggleScripts = () => {
	const cookieConsent = getCookieConsent();

	ga_id = (window.location.hostname === 'log.pt') ? 'XFK7Z45GB5' : 'DEV';

	let ids = {
		gaScript1: 'gaScript1',
		gaScript2: 'gaScript2',
	};

	// Make sure the scripts are removed before adding them.
	for (const [curId] of Object.entries(ids)) {
		let el = document.getElementById(curId);

		if (el) {
			document.head.removeChild(el);
		}
	};

	if (cookieConsent.includes('statistics')) {
		// Google Analytics Script 1
		const gaScript1 = document.createElement('script');
		gaScript1.id = ids.gaScript1;
		gaScript1.className = ids.gaScript1 + Date.now();
		gaScript1.src = `https://www.googletagmanager.com/gtag/js?id=G-${ga_id}`;
		gaScript1.async = true;
		document.head.appendChild(gaScript1);

		// Google Analytics Script 2
		const gaScript2 = document.createElement('script');
		gaScript2.id = ids.gaScript2;
		gaScript2.className = ids.gaScript2 + Date.now();
		gaScript2.appendChild(
			document.createTextNode(
				`window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'G-${ga_id}');`
			)
		);
		document.head.appendChild(gaScript2);
	}
};

const removeCookies = (types) => {
	if (!types.includes('statistics')) {
		Cookies.remove('_ga');
		Cookies.remove('_ga', { domain: '.log.pt' });
		Cookies.remove(`_ga_${ga_id}`);
		Cookies.remove(`_ga_${ga_id}`, { domain: '.log.pt' });
	}
};

const CookieActions = {
	'getCookieConsent': getCookieConsent,
	'setCookieConsent': setCookieConsent,
	'setCookieConsentSel': setCookieConsentSel,
	'selAllCookieConsent': selAllCookieConsent,
	'showCookieNotice': showCookieNotice,
	'hideCookieNotice': hideCookieNotice,
	'showCookieModal': showCookieModal,
	'hideCookieModal': hideCookieModal,
	'toggleScripts': toggleScripts,
};

export default CookieActions;