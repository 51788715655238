import React, { useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import '../assets/scss/main.scss'
import Header from './Header'
import Footer from './Footer'
import CookieActions from './CookieActions'
import CookieNotice from './CookieNotice'
import CookieModal from './CookieModal'
import { useLocation } from '@reach/router'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Helmet } from 'react-helmet'

const Layout = ({ children, ...props }) => {
  const location = useLocation()

  /**
   * Load extra scripts upon cookie consent verification.
   * We're checking the header element because it only changes once per page load, which is useful for reloading scripts when clicking a link.
   *
   * @see https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
   */
  useEffect(() => {
    CookieActions.toggleScripts()
  }, [typeof document !== 'undefined' && document.getElementById('#header')])

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              en {
                seo {
                  title
                  author
                  meta
                  schema
                }
                pages {
                  name
                  link
                  cl
                }
              }
              pt {
                seo {
                  title
                  author
                  meta
                  schema
                }
                pages {
                  name
                  link
                  cl
                }
              }
            }
          }
        }
      `}
      render={data => {
        const isEn = props.lang === 'en'
        const pages = isEn
          ? data.site.siteMetadata.en.pages
          : data.site.siteMetadata.pt.pages
        const seo = isEn
          ? data.site.siteMetadata.en.seo
          : data.site.siteMetadata.pt.seo
        // const currentPage = isEn ? pages.filter(item => item.link === location.pathname).pop() : pages.filter(item => item.link === location.pathname).pop();

        let docTitle = seo.title

        if (props.title) {
          docTitle += ` - ${props.title}`
          props.title = docTitle
        }

        // @TODO: Replace or remove the regex method with this commented JSON one if there's no need to worry about 'og:*' and 'twitter:*' attributes.
        // if (props.meta) {
        // 	let metaJSON = JSON.parse(seo.meta);

        // 	for (const [key, value] of Object.entries(props.meta)) {
        // 		if (value && metaJSON[key]) {
        // 			metaJSON[key] = value.replace(/"/g, '').replace(/'/g, '');
        // 		}
        // 	}

        // 	seo.meta = JSON.stringify(metaJSON);
        // }

        if (props.meta) {
          for (const [key, value] of Object.entries(props.meta)) {
            if (!value.length) {
              continue
            }

            let metaValue = value.replace(/"/g, '').replace(/'/g, '')

            let metaExpr = new RegExp(`"(.*?:?)(${key})":".*?"`, 'g')
            let metaRep = `"$1$2":"${metaValue}"`

            seo.meta = seo.meta.replace(metaExpr, metaRep)
          }
        }

        return (
          <ParallaxProvider>
            <div {...props.rootProps}>
              <Helmet
                htmlAttributes={{ lang: props.lang }}
                title={docTitle}
                titleTemplate={docTitle}
                description={seo.meta.description}
              >
                {Object.entries(JSON.parse(seo.meta)).map(([key, value]) => (
                  <meta key={key} name={key} content={value} />
                ))}
                {seo.schema && (
                  <script type="application/ld+json">{seo.schema}</script>
                )}
                <link
                  rel="preload"
                  href="/static/FKDisplay-Regular-e04e752e217f45f45dc2c9ccf2ac4313.ttf"
                  as="font"
                  type="font/woff2"
                />
              </Helmet>
              <Header
                {...props.headerProps}
                location={location.pathname}
                lang={props.lang}
                pages={pages}
              />
              {children}
              <Footer lang={props.lang} />
            </div>

            <CookieNotice lang={props.lang} />
            <CookieModal lang={props.lang} />
          </ParallaxProvider>
        )
      }}
    />
  )
}

export default Layout
