import React from 'react'
import LangSwitcher from './LangSwitcher'
import AnimationLink from './Link'

const MobileMenu = props => (
  <nav>
    {props.pages.map((link, index) => {
      if (link.items) {
        return (<React.Fragment key={link.name}>
          <AnimationLink paintDrip hex="#FFF" className="link depth-0" to={link.link}>
            {link.name}
          </AnimationLink>
          <span className="indent-0"></span>
          {link.items.map(sublink =>
            sublink.items ? (
              <React.Fragment key={sublink.name}>
                <AnimationLink paintDrip hex="#FFF"
                  style={{ marginLeft: `20px` }}
                  className="link depth-0"
                  to={sublink.link}
                >
                  {sublink.name}
                </AnimationLink>
                {sublink.items.map(nestedsublink => (
                  <div key={nestedsublink.name}>
                    <AnimationLink paintDrip hex="#FFF"
                      style={{ marginLeft: `40px` }}
                      className="link depth-2"
                      to={nestedsublink.link}
                    >
                      {nestedsublink.name}
                    </AnimationLink>
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <div key={sublink.name}>
                <AnimationLink paintDrip hex="#FFF"
                  style={{ marginLeft: `20px` }}
                  className="link depth-1"
                  to={sublink.link}
                >
                  {sublink.name}
                </AnimationLink>
              </div>
            )
          )}
        </React.Fragment>)
      }
      if (props.pages.length === index + 1) {
        return (<div key={link.name} className="bg-blue">
          <LangSwitcher />
          <AnimationLink paintDrip hex="#FFF"
            className='button tertiary' to={link.link}>
            {link.name}
          </AnimationLink>
        </div>)
      }
      return (<div key={link.name}>
        <AnimationLink paintDrip hex="#FFF"
          className={`link depth-0 ${props.cl}`} to={link.link}>
          {link.name}
        </AnimationLink>
      </div>)
    })}

  </nav >
)

export default MobileMenu
