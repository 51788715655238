import React from 'react'

import TextGroup, { Image } from './Texts'

import log_logo_white from '../assets/images/footer/log-white.svg'
import lisboa2020_logo from '../assets/images/footer/lisboa-2020.svg'
import portugal2020_logo from '../assets/images/footer/portugal-2020.svg'
import eu_logo from '../assets/images/footer/eu.svg'
import top5_logo from '../assets/images/footer/log-top5.png'
import google_partners from '../assets/images/footer/google-partners.png'
import { useLocation } from '@reach/router';
import CookieActions from './CookieActions';

const Footer = ({ lang }) => {
	const isEn = lang === 'en';
	const location = useLocation();

	return (
		<footer id="footer">
			<div className="content container">
				<TextGroup
					rootProps={{ className: 'align-center' }}
					title={{
						type: 'h2',
						text: isEn ? 'Are you ready to create\nsomething impactful?' : 'Preparado para criar algo com impacto?',
					}}
				/>

				<div className="row entry-text">
					<div className="col-6 col-12-medium row">
						<div className="col-4 col-12-medium">
							<Image image={log_logo_white} title="log Oscon" alt="log Oscon Logo" width="80px" height="30px" />
						</div>
						<div className="col-8 col-12-medium">
							<p>
								{isEn ? 'We are a tech partner founded in 2001, and we continuously reinvent ourselves to add value to our clients\' businesses through impactful technology.' : 'Fundada em 2001, a log é um parceiro tecnológico que se reinventa constantemente para agregar valor aos negócios de seus clientes através de tecnologia de impacto.'}
							</p>
						</div>
					</div>
					<div className="col-3"></div>
					<div className="col-3 col-12-medium">
						<p>
							<b>hello@log.pt</b>
						</p>
						<p>
							Rua Rodrigues Faria 103, Edifício I - 4º andar 1300-501 Lisboa
							Portugal
						</p>
					</div>
					<div className="col-12 eu-logos">
						<Image image={lisboa2020_logo} title="Lisboa 2020" alt="Lisboa 2020 Logo" width="80" height="30" />
						<Image image={portugal2020_logo} title="Portugal 2020" alt="Portugal 2020 Logo" width="80" height="30" />
						<Image image={eu_logo} title="European Union" alt="European Union Logo" width="80" height="30" />
						<Image image={top5_logo} title="Scoring Top 5%" alt="Scoring Top 5% Logo" className="logo-top5" width="80" height="80" />
						<Image image={google_partners} title="Google Partner" alt="Google Partner Logo" width="80" height="80" />
					</div>

				</div>
			</div>
			<div className="align-center bg-white copyright">
				<ul>
					<li>{new Date().getFullYear()} &copy; Copyright log. All rights reserved.</li>
					<li className="cta" onClick={() => CookieActions.showCookieModal()}>
						{isEn ? 'Cookie Settings' : 'Definições de Cookies'}
					</li>
					<li>
						{isEn ? (
							<a href="/en/strategy-ESG/" className="cta">ESG Strategy</a>
						) : (
							<a href="/estrategia-ESG/" className="cta">Estratégia ESG</a>
						)}
					</li>
				</ul>
			</div>
		</footer>
	);
}

export default Footer
