import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import AnimationLink from './Link';

const LangSwitcher = () => {
  const location = useLocation();
  return (
    <StaticQuery
      query={graphql`
        query LangSwitcherQuery {
          site {
            siteMetadata {
              en {
                langSwitcherPages {
                  link
                }
              }
              pt {
                langSwitcherPages {
                  link
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let link;
        let index;
        let isEn;
        if (data?.site?.siteMetadata && location.pathname.startsWith('/en/')) {
          isEn = true;
          index = data.site.siteMetadata.en.langSwitcherPages.findIndex((item) => item.link === location.pathname);
          link = data.site.siteMetadata.pt.langSwitcherPages[index];
        } else {
          isEn = false;
          index = data.site.siteMetadata.pt.langSwitcherPages.findIndex((item) => item.link === location.pathname);
          link = data.site.siteMetadata.en.langSwitcherPages[index];
        }

        if (typeof link === 'undefined') {
          if (!isEn) {
            link = { link: '/en/' };
          } else {
            link = { link: '/' };
          }
        }

        return (
          <ul className="langSwitcher">
            <li className={isEn ? '' : 'active'}>
              <AnimationLink
                to={isEn ? link.link : '#'}
                paintDrip
                hex="#322EFF"
                onClick={(e) => (isEn ? '' : e.preventDefault())}
              >
                PT
              </AnimationLink>
            </li>
            <li className="disabled">|</li>
            <li className={isEn ? 'active' : ''}>
              <AnimationLink
                to={isEn ? '#' : link.link}
                paintDrip
                hex="#322EFF"
                onClick={(e) => (isEn ? e.preventDefault() : '')}
              >
                EN
              </AnimationLink>
            </li>
          </ul>
        );
      }}
    />
  );
};

export default LangSwitcher;
