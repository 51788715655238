import React from 'react'
import _ from 'lodash'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export const AnimationLink = props => {
  return (
    <AniLink
      {...props}
      trigger={async pages => {
        const entry = await pages.entry
        const scrollingEl = entry.node.querySelector(props.id)
        if (scrollingEl) {
          //scroll to element
          window.requestAnimationFrame(() => {
            scrollingEl.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          })
        } else {
          //scroll to top
          window.requestAnimationFrame(() => {
            entry.node.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          });
        }
      }}
    >
      {props.children}
    </AniLink>
  )
}

export default AnimationLink
