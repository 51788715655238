import React, { useState, useEffect } from 'react';
import CookieActions from './CookieActions';

const CookieNotice = ({ lang }) => {
	const isEn = lang === 'en';

	let cookieConsent = CookieActions.getCookieConsent();
	let cookieNoticeOffEffect = (cookieConsent.length > 0) ? 'instant' : false;
	let [cookieNoticeOff, setCookieNoticeOff] = useState(cookieNoticeOffEffect);

	useEffect(() => {
		if (cookieNoticeOff) {
			CookieActions.hideCookieNotice(cookieNoticeOff);
		} else {
			CookieActions.showCookieNotice();
		}
	});

	return (
		<div className="cookie-notice js-cookie-notice off">
			<div className="cookie-notice__inner">
				<p>{isEn ? 'Our website uses cookies.' : 'O nosso site usa cookies.'}</p>

				<button
					type="button"
					className="button secondary"
					onClick={() => {
						CookieActions.hideCookieNotice('fadeOut');
						CookieActions.showCookieModal();
					}}
				>
					{isEn ? 'Learn more' : 'Saber mais'}
				</button>

				<button
					type="button"
					className="button primary log-ga-gdpr-accept"
					onClick={() => {
						CookieActions.hideCookieNotice();
						CookieActions.selAllCookieConsent();
						CookieActions.setCookieConsentSel();
						setCookieNoticeOff('fadeOut');
					}}
				>
					{isEn ? 'Accept cookies' : 'Aceitar cookies'}
				</button>
			</div>
		</div>
	)
};

export default CookieNotice;