import React, { useState, useEffect } from 'react';
import CookieActions from './CookieActions';

const CookieModal = ({ lang }) => {
	const isEn = lang === 'en';

	let cookieConsent = CookieActions.getCookieConsent();
	let cookieNoticeOffEffect = (cookieConsent.length > 0) ? 'instant' : false;
	let [cookieNoticeOff, setCookieNoticeOff] = useState(cookieNoticeOffEffect);
	let [gaGdpr, setGaGdpr] = useState(cookieConsent.includes('statistics'));

	useEffect(() => {
		if (cookieNoticeOff) {
			CookieActions.hideCookieNotice(cookieNoticeOff);
		}
	});

	return (
		<div className="cookie-modal off js-cookie-modal">
			<div className="cookie-modal__inner">
				<h2>Cookies</h2>
				<p>{isEn ? 'Here you can review and choose your consent regarding the cookies we use on our website.' : 'Aqui poderá consultar e escolher o seu consentimento relativamente às cookies que usamos no nosso site.'}</p>

				<h3>{isEn ? 'Strictly necessary' : 'Estritamente necessárias'}</h3>
				<p>{isEn ? 'These cookies cannot be deactivated, as our website requires them to operate correctly.' : 'Estas cookies não podem ser desativadas, uma vez que o correto funcionamento do nosso site depende delas.'}</p>

				<table>
					<thead>
						<tr>
							<th>Cookie(s)</th>
							<th>{isEn ? 'Description' : 'Descrição'}</th>
							<th className="center">{isEn ? 'Consent' : 'Consentimento'}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>cookieConsent</td>
							<td>{isEn ? 'This cookie saves your consent regarding all the other cookies.' : 'Esta cookie guarda o seu consentimento relativamente a todas as outras.'}</td>
							<td className="center">
								<input id="cookieConsent-necessary" className="js-cookie-consent-check" type="checkbox" value="" checked disabled />
								<label htmlFor="cookieConsent-necessary" />
							</td>
						</tr>
					</tbody>
				</table>

				<h3>{isEn ? 'Statistics' : 'Estatísticas'}</h3>

				<table>
					<thead>
						<tr>
							<th>Cookie(s)</th>
							<th>{isEn ? 'Description' : 'Descrição'}</th>
							<th className="center">{isEn ? 'Consent' : 'Consentimento'}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{isEn ? '_ga (and derived)' : '_ga (e derivados)'}</td>
							<td>
								{isEn ? 'Cookies starting with "_ga" are part of the Google Analytics service, which we use to analyse our traffic anonimously and improve our services. For more information, ' : 'As cookies com nomes que começam em "_ga" fazem parte do serviço Google Analytics, que usamos para analisar o nosso tráfego de forma anónima e de forma a melhorar os nossos serviços. Para obter mais informações, '}

								<a href="https://support.google.com/analytics/answer/6004245#zippy=%2Cgoogle-analytics-cookies-and-identifiers" target="_blank" rel="noreferrer">
									{isEn ? 'click here' : 'clique aqui'}
								</a>.
							</td>
							<td className="center">
								<input id="cookieConsent-statistics" className="js-cookie-consent-check" type="checkbox" value="statistics" checked={gaGdpr} onChange={(event)=>setGaGdpr(event.target.checked)} />
								<label htmlFor="cookieConsent-statistics" />
							</td>
						</tr>
					</tbody>
				</table>

				<div className="cookie-modal__actions">
					<button
						type="button"
						className={gaGdpr ? "button secondary log-ga-gdpr-accept" : "button secondary"}
						onClick={() => {
							CookieActions.setCookieConsentSel();
							CookieActions.hideCookieModal();
							setCookieNoticeOff('instant');
						}}
					>
						{isEn ? 'Accept selected' : 'Aceitar seleção'}
					</button>

					<button
						type="button"
						className="button primary log-ga-gdpr-accept"
						onClick={() => {
							CookieActions.selAllCookieConsent();
							CookieActions.setCookieConsentSel();
							CookieActions.hideCookieModal();
							setCookieNoticeOff('instant');
						}}
					>
						{isEn ? 'Accept all' : 'Aceitar tudo'}
					</button>
				</div>
			</div>
		</div>
	)
};

export default CookieModal;